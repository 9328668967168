'use client'

import { useAttributionUrlLink } from '@chaseweb/utils/tracking/vendor'
import { useSearchParams } from 'next/navigation'

import { StyledLink } from '@/components'
import { useChaseEnv } from '@/lib/hooks'

export const AppOnlyErrorCta = ({ label }: { label: string }) => {
  const env = useChaseEnv()
  const searchParams = useSearchParams()
  const openCtaLink = useAttributionUrlLink({
    appsflyerLink: env?.openAppUrl ?? '',
    search: searchParams.toString() || '',
  })
  return openCtaLink ? (
    <StyledLink
      data-testid="openCta"
      href={openCtaLink}
      variant="primary"
      role="link"
      className="tw-whitespace-nowrap"
      notTracked
    >
      {label}
    </StyledLink>
  ) : null
}
